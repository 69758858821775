import React from 'react';
import { Layout } from '../../components/Layout';
import { StatusPlan } from '../../components/StatusPlan';

const statusBilling = {
  titleStatus: 'Problemas na cobrança',
  textStatus: 'Seus anúncios estão inativos até a regularização do pagamento.',
  textButton: 'Regularizar pagamento',
  textLink: 'Alterar plano',
};

const statusActiveBilling = {
  titleStatus: 'Seu plano expirou!',
  textStatus: 'Seus anúncios continuam ativos por 5 dias, regularize o pagamento.',
  textButton: 'Regularizar pagamento',
  textLink: 'Alterar plano',
};
const SandBoxCobranca = () => (
  <Layout>
    <StatusPlan status={statusBilling} />
    <br />
    <StatusPlan status={statusActiveBilling} />
  </Layout>
);

export default SandBoxCobranca;
