import React from 'react';
import cx from 'classnames';
import { TransparentOutlineButton } from '../Button';
import { Icon } from '../Icons';
import { LinkWhite } from '../Link';
import styles from './style.module.css';

interface StatusProps {
  titleStatus: string;
  textStatus: string;
  textButton: string;
  textLink: string;
}

interface Props {
  status: StatusProps;
}

export const StatusPlan = ({ status }: Props) => {
  const { titleStatus, textStatus, textButton, textLink } = status;
  const paragraph = cx('f13 fw4 ma0', styles.paragraph);
  const handlePayment = () => {
    // eslint-disable-next-line no-console
    console.log('pagamento efetuado');
  };
  const handleChangePlan = () => {
    // eslint-disable-next-line no-console
    console.log('plano alterado');
  };
  return (
    <div className="flex flex-column flex-row-m flex-row-l justify-between-m justify-between-l bg-abbey ph16 pt12 pb16 pb12-m pb-12-l">
      <div className="white flex items-center-ns mb12 mb0-m mb0-l w-100">
        <div className="mr8 mt8 mt0-ns">
          <Icon name="SvgIconWarning" width={24} height={24} />
        </div>
        <div>
          <h4 className="f14 fw6 ma0">{titleStatus}</h4>
          <p className={paragraph}>{textStatus}</p>
        </div>
      </div>

      <div className="flex flex-column flex-row-reverse-ns items-center justify-start-ns w-100">
        <TransparentOutlineButton className={styles.btnBilling} onClick={handlePayment}>
          {textButton}
        </TransparentOutlineButton>
        <LinkWhite className="mt24 mt0-ns mr48-ns" onClick={handleChangePlan}>
          {textLink}
        </LinkWhite>
      </div>
    </div>
  );
};
